$alerts-warning: #FFC542;
$alerts-error: #FC5A5A;
$alerts-success: #22C55E;
$border: #E6E8EC;
$orange: #FB923C;
$violet: #8B5CF6;
$pink: #EE5DA5;
$amber: #F59E0B;
$primary-50: #E6F0FF;
$primary-100: #B0CFFF;
$primary-200: #8AB8FF;
$primary-300: #5497FF;
$primary-400: #3383FF;
$primary-500: #0064FF;
$primary-600: #005BE8;
$primary-700: #0047B5;
$primary-800: #00378C;
$primary-900: #002A6B;
$secondary-50: #E6F8FF;
$secondary-100: #C2EEFF;
$secondary-200: #91E0FF;
$secondary-300: #5ED2FF;
$secondary-400: #2EC5FF;
$secondary-500: #00B8FF;
$secondary-600: #009CD9;
$secondary-700: #0083B5;
$secondary-800: #006991;
$secondary-900: #005373;
$dark-50: #FFF;
$dark-100: #FAFAFB;
$dark-200: #F1F1F5;
$dark-300: #E2E2EA;
$dark-400: #D5D5DC;
$dark-500: #B5B5BE;
$dark-600: #92929D;
$dark-700: #353945;
$dark-800: #44444F;
$dark-900: #171725;
$gradient-bright: radial-gradient(87.68% 87.68% at 95.36% 14.36%, #FFCF14 0%, #EE8FFF 55.86%, #38DEFF 100%);
$gradient-light: radial-gradient(87.68% 87.68% at 95.36% 14.36%, #FFD840 0%, #F3ACFF 55.86%, #8AECFF 100%);