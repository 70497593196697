@import 'helpers';

.categories {
  padding: rem(56px) 0;
}

.list {
  display: flex;
  flex-wrap: wrap;
  gap: rem(16px);
  justify-content: center;
  max-width: rem(980px);
  margin: 0 auto;
}

.listItem {
  max-width: rem(256px);
  width: 100%;
}